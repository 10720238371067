
/**
 * globalne promenjive
 */


export const VERSION = "1.0.11";

//putanje
export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASENAME = "/cz-parking_dev";

//datumi
export const DATE_FORMAT = "yyyy-MM-dd";

//role
export const ROLA = "rola";
export const ADMIN = "2";
export const USER = "1";

//colors
export const BG_COLOR_TABLE_RED = "#901836";

//mode i rezimi
export const MODE_STANDARD = "1"; //standardni
export const MODE_TEMPORARY = "2"; //privremeni
export const REGIME_MATCH = "1"; //rezim utakmice
export const REGIME_STANDARD = "2";//standardni rezim parkiranja kad nema utakmice

export const objModes = {
    mode_standard:1,
    mode_temporary:2,
    regime_match:1,
    regime_standard:2,
    mode_standard_name:"STALNI",
    mode_temporary_name:"PRIVREMENI",
    arrMode:['', "STALNI", "PRIVREMENI"],
    arrRegime:['','REŽIM UTAKMICE', 'REŽIM STANDARD'],
};





