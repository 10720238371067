/**
 * forma za eksport kartica u white listu
 * @author DAL (VS)
 * @version 1.0.1
 */

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaBan, FaCheck } from "react-icons/fa";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { BASE_URL, BG_COLOR_TABLE_RED, DATE_FORMAT } from "../Global";

function ExportToWL() {
  const stylesTableWL = {
    headCells: {
      style: {
        backgroundColor: BG_COLOR_TABLE_RED,
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  };

  const NO_DATE_VALID = "bez važenja";

  //glavna tabela
  const columnsTableWL = [
    {
      name: "RB",
      selector: (row) => row.rb,
      sortable: true,
      width: "100px",
    },
    {
      name: "KORISNIK",
      selector: (row) => row.ime,
      sortable: true,
      width: "250px",
    },
    {
      name: "QR KOD",
      selector: (row) => row.qr_code,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {row.qr_code}
        </div>
      ),
    },
    {
      name: "TIP",
      selector: (row) => row.korisnik_mod,
      sortable: true,
      width: "120px",
    },
    {
      name: "REŽIM PRISTUPA",
      selector: (row) => row.korisnik_rezim,
      sortable: true,
      width: "200px",
    },
    {
      name: "QR AKTIVAN",
      selector: (row) => {
        return row.aktivan === 1 ? "DA" : "NE";
      },
      sortable: true,
      width: "130px",
    },
    {
      name: "VAŽENJE DO",
      selector: (row) => row.datum_vazenja,
      sortable: true,
    },
    {
      name: "VALIDAN",
      selector: (row) => {
        return handleDateValid(row.datum_vazenja) ? <FaBan /> : <FaCheck />;
      },
      sortable: true,
    },
  ];

  const [cardsData, setEventData] = useState([]);
  const [isGenerQr, setGenerQr] = useState(false);
  const [generQrDate, setGenerQrDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [dateForExport, setDateForExport] = useState(false);

  const NoDataCards = () => <div>Ne postoje podaci !</div>;

  useEffect(() => {
    getAllCards();
  }, []);

  const handleDateValid = (dateValid) => {
    if (dateValid === null) {
      return NO_DATE_VALID;
    }
    const dg = format(dateValid, DATE_FORMAT);
    const dnow = format(new Date(), DATE_FORMAT);

    const dgTS = new Date(dg).getTime();
    const dnowTS = new Date(dnow).getTime();

    setDateForExport(dgTS < dnowTS);
    return dgTS < dnowTS;
  };

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = cardsData.filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" && field.toLowerCase().includes(value)
      )
    );
    setFilterText(value);
    setFilteredData(filtered);
  };

  const handleExport = () => {
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Svi raniji podaci će biti obrisani !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        exportToWl();
      }
    });
  };


  const handleDeleteExportList = () => {
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Svi raniji podaci će biti obrisani !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQrCodes();
        // getAllCards();
      }
    });
  };

  const handleGenerQr = () => {
    setGenerQr(true);
  };

  const handleGenerQrDate = (date) => {
    setGenerQrDate(date);
  };

  const handleCloseGenerQr = () => {
    setGenerQr(false);
    setGenerQrDate("");
  };

  const handleGenerateQr = () => {
    if (!generQrDate) {
      return;
    }
    const dg = format(generQrDate, DATE_FORMAT);
    const dnow = format(new Date(), DATE_FORMAT);

    const dgTS = new Date(dg).getTime();
    const dnowTS = new Date(dnow).getTime();

    if (dgTS < dnowTS) {
      toast.error("Greška u unosu datuma !", {
        theme: "colored",
        hideProgressBar: true,
      });
      return;
    }
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Svi raniji podaci će biti obrisani !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQrCodes();
        generateQr(dgTS);
      }
    });
  };

  //glavna funkcija za generisanje qr-a
  const generateQr = async (dgTS) => {
    const url = `${BASE_URL}/generateQr/${dgTS}`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 500) {
        toast.error(response.data.result, {
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        getAllCards();
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };

  //brisanje svih qr kodova
  const deleteQrCodes = async () => {
    const url = `${BASE_URL}/deleteQrCodes`;
    try {
      const response = await axios.delete(url);
      if (response.data.status === 200) {
        setEventData(response.data.result);
        setFilteredData(response.data.result);
        getAllCards();
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //prikazi sve kartice iz tabele karata
  const getAllCards = async () => {
    const url = `${BASE_URL}/getAllCards`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        setEventData(response.data.result);
        setFilteredData(response.data.result);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //eksport kartica u tabelu white liste
  const exportToWl = async () => {
    const url = `${BASE_URL}/exportToWl`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno su unete kartice u white listu !",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>EKSPORT KARTICA</h5>
        </div>
        <div className="col-md-4 float-end">
          <input
            type="text"
            className="form-control"
            placeholder="Filtriranje..."
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="card-body">
        <label htmlFor="idCUNote" className="float-start">
          Lista kartica za white listu
        </label>
        <p className="float-end small text-danger fw-bolder">
          *korisnici koji imaju aktivne QR kodove
        </p>
        <DataTable
          columns={columnsTableWL}
          data={filteredData}
          fixedHeader
          pagination={true}
          striped={true}
          highlightOnHover={true}
          paginationPerPage={10}
          customStyles={stylesTableWL}
          fixedHeaderScrollHeight="400px"
          noDataComponent={<NoDataCards />}
        />
      </div>
      <div className="card-footer mt-4 bg-white">
        <button
          className="col-md-3 btn btn-outline-primary"
          title="Automatsko generisanje QR kodova"
          onClick={handleGenerQr}
        >
          GENERISANJE QR KODOVA
        </button>
        <button
          className="col-md-3 btn btn-outline-primary"
          disabled={dateForExport}
          onClick={handleExport}
        >
          EKSPORT KARTICA U WHITE LISTU
        </button>
        
        <button
          className="col-md-3 btn btn-outline-danger float-end"
          title="Brisanje unetih QR kodova i njihovih korisnika iz liste za eksport kartica"
          onClick={handleDeleteExportList}
        >
          BRISANJE QR KODOVA
        </button>
      </div>

      {isGenerQr && (
        <div className="my-modal-dialog-two">
          <div
            className="card my-modal-dialog"
            style={{ width: "20%", marginLeft: "10%", marginTop: "-10%" }}
          >
            <div className="my-card-header">
              <h6>GENERISANJE QR KODOVA</h6>
            </div>
            <div className="card-body">
              <div className="form-group col-md-8">
                <label htmlFor="generQrDate">Unesite datum važenja</label>

                <DatePicker
                  selected={generQrDate}
                  onChange={handleGenerQrDate}
                  value={generQrDate}
                  className="form-control"
                  name="activesince"
                  style={{ width: "700px" }}
                  dateFormat={"YYYY-MM-dd"}
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                className="btn btn-outline-primary col-md-4 float-start "
                disabled={!generQrDate}
                onClick={handleGenerateQr}
              >
                GENERIŠI
              </button>
              <button
                className="btn btn-outline-success col-md-4 float-end"
                onClick={handleCloseGenerQr}
              >
                ZATVORI
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default ExportToWL;
