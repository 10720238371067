
/**
 * @author DAL (VS)
 * @version 1.0.3
 */

import { useEffect } from 'react'; 
import axios from 'axios';
import { Navigate } from 'react-router-dom';

import { BASE_URL } from "./Global";

export default function Logout({ onLogout }){

    useEffect(()=>{

        const fetchData = async() => {
            try{
                await axios.get(`${BASE_URL}/logout`);
                onLogout();
            }catch(error){
                console.error(error);
            }
        }
        fetchData();
        localStorage.setItem('login', false);
        localStorage.removeItem('rola');
        
    }, []);

    return (
        <Navigate to="/" replace={true} />
    )
}




