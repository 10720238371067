import React from "react";


const Container =({children})=>{
    return <div className="container" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 40,
            marginLeft:'22%'
          }}>{children}</div>
};

export default Container;