/**
 * forma za rad sa citacima
 * @author DAL (VS)
 * @version 1.0.2
 */

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaPen, FaTrash } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { BASE_URL, BG_COLOR_TABLE_RED } from "./Global";

function Readers() {
  const stylesTableReg = {
    table: {
      style: {
        width: "100%",
        minWidth: "700px",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        backgroundColor: BG_COLOR_TABLE_RED,
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  };

  const columnsTableReg = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "OPIS",
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: "TIP",
      selector: (row) => row.type,
      width: "80px",
      sortable: true,
    },
    {
      name: "AKTIVAN",
      selector: (row) => row.active,
      width: "100px",
    },
    {
      name: "PRISTUP",
      selector: (row) => row.foraccess,
      width: "120px",
    },
    {
      name: "ODJAVA",
      selector: (row) => row.forcheckout,
      width: "120px",
    },
    {
      name: "SN",
      selector: (row) => row.sn,
      width: "150px",
    },
    {
      name: "KREIRAN",
      selector: "created_at",
      cell: (row) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {row.created_at}
        </div>
      ),
    },
    {
      name: "AŽURIRAN",
      selector: "updated_at",
      cell: (row) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {row.updated_at}
        </div>
      ),
    },
    {
      name: "EDIT",
      button: true,
      cell: (row) => (
        <div>
          <button
            className="btn btn-outline-primary m-1"
            title="Editovanje"
            onClick={(e) => handleUpReader(row.id)}
          >
            <FaPen />
          </button>
          <button
            className="btn btn-outline-danger m-1"
            title="Brisanje"
            onClick={(e) => handleDelReader(row.id)}
          >
            <FaTrash />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  useEffect(() => {
    fetchAllReaders();
  }, []);

  const NoDataCards = () => <div>Ne postoje uneti čitači !</div>;

  //states
  const [isEditVisible, setEditVisible] = useState(false);
  const [readersData, setReadersData] = useState([]);
  const [editMode, setEditMode] = useState(false);

  //reader
  const [reader, setReader] = useState({
    id: "",
    type: "",
    sn: "",
    desc: "",
  });

  //checkbox
  const [boxcheck, setCheckBoxs] = useState({
    active: false,
    for_access: false,
    for_checkout: false,
  });

  //errors
  const [errSn, setErrorSn] = useState("");
  const [errDesc, setErrorDesc] = useState("");

  //funkcije
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setCheckBoxs({
        ...boxcheck,
        [name]: checked,
      });
    } else {
      setReader({
        ...reader,
        [name]: value,
      });
    }
  };

  const visibleNewReadersForm = () => {
    setEditVisible(!isEditVisible);
    setReader("");
    setCheckBoxs(false);
  };

  const hideNewReadersForm = () => {
    setErrorDesc("");
    setErrorSn("");
    setEditMode(false);
    setEditVisible(!isEditVisible);
  };

  const handleUpReader = (row) => {
    setEditMode(true);
    setEditVisible(true);
    setReader("");
    setCheckBoxs(false);
    setErrorDesc("");
    setErrorSn("");
    fetchReader(row);
  };

  const handleDelReader = (id) => {
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Obrisani čitač će biti trajno obrisan !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReader(id);
      }
    });
  };

  //preuzmi sve citace
  const fetchAllReaders = async () => {
    const url = `${BASE_URL}/fetchAllReaders`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        setReadersData(response.data.result);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //preuzmi jedan citac
  const fetchReader = async (id) => {
    const url = `${BASE_URL}/fetchReader/${id}`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        const rd = response.data.result;

        setReader({
          id: rd.reader.id,
          type: rd.reader.type,
          sn: rd.reader.sn,
          desc: rd.reader.desc,
        });

        setCheckBoxs({
          active: Number(rd.reader.active),
          for_access: Number(rd.reader.foraccess),
          for_checkout: Number(rd.reader.forcheckout),
        });
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //snimi nov citac
  const saveNewReader = async () => {
    setErrorDesc("");
    setErrorSn("");

    const url = editMode
      ? `${BASE_URL}/updateReader`
      : `${BASE_URL}/saveNewReader`;

    const data = {
      id: reader.id,
      type: reader.type || "U",
      sn: reader.sn || 0,
      desc: reader.desc || "",
      active: Number(boxcheck.active) || 0,
      for_access: Number(boxcheck.for_access) || 0,
      for_checkout: Number(boxcheck.for_checkout) || 0,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je unet čitač !",
          showConfirmButton: false,
          timer: 2000,
        });

        fetchAllReaders();
      } else if (response.data.status === 500) {
        toast.error(response.data.result);
      } else {
        Object.entries(response.data).forEach(([key, val]) => {
          switch (key) {
            case "sn":
              setErrorSn(val);
              break;
            case "desc":
              setErrorDesc(val);
              break;

            default:
              break;
          }
        });
        toast.error("Greška u unosu podataka !");
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //brisanje citaca
  const deleteReader = async (id) => {
    const url = `${BASE_URL}/deleteReader/${id}`;
    try {
      const response = await axios.delete(url);
      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je obrisan čitač !",
          showConfirmationButton: false,
          timer: 2000,
        });
        fetchAllReaders();
      } else {
        toast.error(response.data.result);
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>TABELA ČITAČA</h5>
        </div>
      </div>

      <div className="card-body">
        <DataTable
          columns={columnsTableReg}
          data={readersData}
          fixedHeader
          pagination={true}
          striped={true}
          highlightOnHover={true}
          paginationPerPage={10}
          customStyles={stylesTableReg}
          fixedHeaderScrollHeight="400px"
          noDataComponent={<NoDataCards />}
        />
      </div>
      <div className="card-footer mt-4 bg-white">
        <button
          className="col-md-3 btn btn-outline-primary float-start"
          onClick={visibleNewReadersForm}
        >
          UNOS NOVOG ČITAČA
        </button>
      </div>

      {/* unos novog citaca */}
      {isEditVisible && (
        <div className="my-modal-dialog-two">
          <div
            className="card my-modal-dialog"
            style={{ width: "40%", marginLeft: "10%", marginTop: "-10%" }}
          >
            <div className="my-card-header">
              <h5>{editMode ? "IZMENA ČITAČA" : "UNOS NOVOG ČITAČA"}</h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="d-flex justify-content-between">
                  <div className="form-check form-switch form-check-inline">
                    <label htmlFor="active" className="form-check-label">
                      Aktivan
                    </label>
                    <input
                      className="form-check-input form-control"
                      type="checkbox"
                      role="switch"
                      name="active"
                      checked={boxcheck.active}
                      aria-labelledby="switch-label"
                      onChange={handleChange}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="col col-md-2 form-group">
                    <label htmlFor="id">ID Čitača</label>
                    <input
                      className="form-control text-center"
                      disabled
                      name="id"
                      value={reader.id}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="row g-3">
                <div className="col form-group col-md-2">
                  <label htmlFor="type">Tip čitača</label>
                  <select
                    className="col form-select"
                    name="type"
                    value={reader.type}
                    onChange={handleChange}
                  >
                    <option value="U">U</option>
                    <option value="I">I</option>
                  </select>
                </div>

                <div className="col form-group col-md-5">
                  <label htmlFor="desc">Opis čitača*</label>
                  <span className="float-end text-small text-danger">
                    {errDesc}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="desc"
                    value={reader.desc}
                    onChange={handleChange}
                  />
                </div>

                <div className="col form-group col-md-5">
                  <label htmlFor="sn">SN čitača*</label>
                  <span className="float-end text-small text-danger">
                    {errSn}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="sn"
                    title={reader.sn}
                    value={reader.sn}
                    onChange={handleChange}
                  />
                </div>

                <div className="d-flex justify-content-around">
                  <div className="form-check form-switch form-check-inline">
                    <label htmlFor="for_access" className="form-check-label">
                      ZA PRISTUP
                    </label>
                    <input
                      className="form-check-input form-control"
                      type="checkbox"
                      role="switch"
                      name="for_access"
                      checked={boxcheck.for_access}
                      aria-labelledby="switch-label"
                      onChange={handleChange}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="form-check form-switch form-check-inline">
                    <label htmlFor="for_checkout" className="form-check-label">
                      ZA ODJAVU
                    </label>
                    <input
                      className="form-check-input form-control"
                      type="checkbox"
                      role="switch"
                      name="for_checkout"
                      checked={boxcheck.for_checkout}
                      aria-labelledby="switch-label"
                      onChange={handleChange}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer">
              <button
                className="btn btn-outline-primary col-md-4 float-start "
                onClick={(e) => saveNewReader()}
              >
                SNIMI
              </button>
              <button
                className="btn btn-outline-success col-md-4 float-end"
                onClick={hideNewReadersForm}
              >
                ZATVORI
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default Readers;
