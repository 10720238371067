/**
 *provera login kredencijala
 *@author DAL (VS)
 *@version 1.0.3
 **/
import { useState} from "react";
import { useNavigate } from "react-router-dom";
import "../css/Login.css";

import { BASE_URL } from "./Global";

function Login({ onLogin }) {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e, type) => {
    setError("");
    switch (type) {
      case "user":
        setUser(e.target.value);
        if (e.target.value === "") {
          setError("Morate uneti korisničko ime !");
          // setUser.autoFocus();
        }
        break;
      case "pass":
        setPass(e.target.value);
        if (e.target.value === "") {
          setError("Morate uneti lozinku !");
        }
        break;
      default:
    }
  };

  function loginSubmit() {
    if (user !== "" && pass !== "") {
      const url = `${BASE_URL}/login`;
      const headers = {
        Accept: "application/json",
        "Content-type": "application/json",
      };
      const Data = {
        username: user,
        password: pass,
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          switch (response.status) {
            case 200:
              setSuccess("Uspešno logovanje");
              localStorage.setItem("login", true);
              localStorage.setItem("rola", response.rola);
              onLogin(true);
              navigate("/main", { replace: true });
              break;
            case 500:
              setError("Greška u unosu kredencijala !");
              break;
            default:
          }
        })
        .catch((err) => {
          alert(err);
        });
      return;
    }
    setError("Sva polja su obavezna !");
  }
  return (
    <div className="display-overlay">
      <div className="login-page">
        <div className="form">
          <p>
            {error !== "" ? (
              <span className="error">{error}</span>
            ) : (
              <span className="success">{success}</span>
            )}
          </p>

          <input
            type="text"
            placeholder="Korisničko ime"
            className="is-invalid"
            value={user}
            required
            autoFocus
            onChange={(e) => handleInputChange(e, "user")}
          />

          <input
            type="password"
            placeholder="Lozinka"
            className="is-invalid"
            value={pass}
            required
            onChange={(e) => handleInputChange(e, "pass")}
          />
          <span className="invalid-feedback mb-1 fw-bolder " role="alert" />
          <button type="submit" onClick={loginSubmit}>
            LOGOVANJE
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
