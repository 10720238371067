/**
 * forma za proveru kartica u white listi
 * @author DAL (VS)
 * @version 1.0.2
 */

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import { BASE_URL, BG_COLOR_TABLE_RED, DATE_FORMAT } from "../Global";

function CheckWL() {
  const stylesTableWL = {
    headCells: {
      style: {
        backgroundColor: BG_COLOR_TABLE_RED,
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  };

  const columnsTableWL = [
    {
      name: "RB",
      selector: (row) => row.rb,
      sortable: true,
      width:'100px',
    },
    {
      name: "QR",
      selector: (row) => row.qr_code,
      sortable: true,
    },
    {
      name: "IME I PREZIME",
      selector: (row) => row.ime,
      sortable: true,
    },
    {
      name: "DATUM VAŽENJA",
      selector: (row) => row.dateexp,
      sortable: true,
    },
    {
      name: "VREME KREIRANJA",
      selector: (row) => row.created_at,
      sortable: true,
    },
  ];

  useEffect(() => {
   // getCheckWL();
  }, []);

  const [cardsData, setEventData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [dataLength, setDataLength] = useState(0);

  const NoDataCards = () => <div>Ne postoje podaci !</div>;

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = cardsData.filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" && field.toLowerCase().includes(value)
      )
    );
    setFilterText(value);
    setFilteredData(filtered);
  };

  //prikazi sve kartice iz tabele za white listu
  const getCheckWL = async () => {
    const url = `${BASE_URL}/checkWL`;
    try {
      const response = await axios.get(url);

      if (response.data.status === 200) {
        const result = response.data.result;
        setEventData(result);
        setFilteredData(result);
        setDataLength(result.length);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno su importovane kartice iz white liste !",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  const handleCreateFileWl = async () => {
    const url = `${BASE_URL}/createFileWl`;
    try {
      const response = await axios.get(url);

      if (response.data.status === 200) {
        const today = format(new Date(), DATE_FORMAT);
        let txtContent = "";
        const header = `White lista na dan:\t ${today} \n\n`;
        response.data.result.forEach((row) => {
          txtContent += `${row.qr_code}\t${row.ime}\n`;
        });

        const file = header + txtContent;
        // Kreiraj Blob za txt fajl
        const blob = new Blob([file], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `white_list${today}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="card col-md-9">
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>PROVERA WHITE LISTE</h5>
        </div>
        <div className="col-md-4 float-end">
          <input
            type="text"
            className="form-control"
            placeholder="Filtriranje..."
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="card-body">
        <label htmlFor="idCUNote">Lista kartica white liste</label>
        <DataTable
          columns={columnsTableWL}
          data={filteredData}
          fixedHeader
          pagination={true}
          striped={true}
          highlightOnHover={true}
          paginationPerPage={10}
          customStyles={stylesTableWL}
          fixedHeaderScrollHeight="400px"
          noDataComponent={<NoDataCards />}
        />
      </div>
      <div className="card-footer mt-4 bg-white">
        <button
          className="col-md-3 btn btn-outline-primary float-start"
          onClick={() => getCheckWL()}
        >
          IMPORT KARTICA IZ WHITE LISTE
        </button>
        <button
          className="col-md-3 btn btn-outline-primary float-end"
          disabled={!dataLength}
          onClick={handleCreateFileWl}
        >
          KREIRAJ FAJL
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CheckWL;
