/**
 * forma za unos korisnika kartica
 * @author DAL (VS)
 * @version 1.0.2
 */

import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";

import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BASE_URL, MODE_STANDARD, MODE_TEMPORARY, objModes } from "./Global";

function CardUsers() {
  const [idCardUser, setIdUser] = useState(0);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [tables, setTables] = useState("");
  const [note, setNote] = useState("");

  //rezimi
  const [selectMode, setSelectMode] = useState("");
  const [selectRegime, setSelectRegime] = useState("");

  const [disabled, setDisabled] = useState("disabled");
  const [errName, setErrName] = useState("");
  const [errLastName, setErrLastName] = useState("");

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [btnNewState, setBtnNewState] = useState("");
  const [btnEditState, setBtnEditState] = useState("disabled");
  const [btnCancelState, setBtnCancelState] = useState("disabled");
  const [btnDelState, setBtnDelState] = useState("disabled");
  const [editState, setEditState] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    listCardUser();
  }, []);


  //popuni listu korisnika
  const listCardUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/listCardUser`);
      if (response.data.result) {
        setOptions(response.data.result);
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };

  const toggleDisabled = () => {
    if (!disabled) return;
    clearItems();
    setDisabled(!disabled);
    inputRef.current.focus();
    setEditState(false);
  };

  const changeSelect = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value > 0) {
      fetchWorker(e.target.value);
    } else {
      clearItems();
      setBtnEditState(disabled);
    }
  };

  const editHandle = () => {
    setDisabled(!disabled);
    setBtnNewState("disabled");
    setBtnCancelState(!disabled);
    setBtnDelState(!disabled);
    setEditState(true);
  };

  const cancelHandle = () => {
    setDisabled("disabled");
    setBtnNewState("");
    setBtnCancelState(disabled);
    setBtnEditState("disabled");
    setBtnDelState("disabled");
    setEditState(false);
    setSelectMode("");
    setSelectRegime("");
    setSelectedOption(0);
    clearItems();
  };

  //brisanje inputa gresaka
  const clearErrors = () => {
    setErrName("");
    setErrLastName("");
  };

  //brisanje unetih vrednosti
  const clearItems = () => {
    setName("");
    setLastName("");
    setSelectMode("");
    setSelectRegime("");
    setPhone("");
    setTables("");
    setNote("");
    setDisabled("disabled");
  };

  const handleTables = (e) => {
    setTables(e.toUpperCase());
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Obrisani podatak će biti trajno obrisan !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCardUser(selectedOption);
      }
    });
  };

  /**
   * snimi korisnika
   */
  const saveCardUser = async () => {
    clearErrors();

    const url = editState
      ? `${BASE_URL}/updateCardUser`
      : `${BASE_URL}/saveCardUser`;

    const data = {
      name,
      lastname,
      phone,
      tables,
      selectMode,
      selectRegime,
      note,
      idCardUser,
    };
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je unet podatak !",
          showConfirmButton: false,
          timer: 2000,
        });
        clearItems();
        listCardUser();
        setBtnNewState();
        setSelectedOption(0);
        setBtnCancelState("disabled");
        setBtnEditState("disabled");
        setBtnDelState("disabled");
      } else if (response.data.status === 500) {
        toast.error(response.data.result, {
          theme: "colored",
        });
      } else {
        Object.entries(response.data).forEach(([key, val]) => {
          switch (key) {
            case "name":
              setErrName(val);
              break;
            case "lastname":
              setErrLastName(val);
              break;
            default:
              break;
          }
        });
        toast.error("Greška u unosu podataka !");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  //fetch korisnika
  const fetchWorker = async (id) => {
    const url = `${BASE_URL}/fetchCardUser/${id}`;
    try {
      const response = await axios.get(url);
      switch (response.data.status) {
        case 200:
          setIdUser(response.data.result.idCardUser);
          setName(response.data.result.ime);
          setLastName(response.data.result.prezime);
          setPhone(response.data.result.telefon);
          handleTables(response.data.result.tablice);
          setSelectMode(response.data.result.korisnik_mod.toString());
          setSelectRegime(response.data.result.korisnik_rezim.toString());
          setNote(response.data.result.napomena);
          setBtnEditState("");
          break;
        case 404:
          toast.info("Ne postoje podaci u bazi podataka !");
          break;
        case 500:
          toast.error(response.data.result, {
            theme: "colored",
          });
          break;
        default:
          break;
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
      });
    }
  };

  //brisanje korisnika
  const deleteCardUser = async (id) => {
    const url = `${BASE_URL}/deleteCardUser/${id}`;
    try {
      const response = await axios.delete(url);
      if (response.data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je obrisan korisnik !",
          showConfirmButton: false,
          timer: 2000,
        });
        clearItems();
        listCardUser();
        setBtnNewState();
        setSelectedOption(0);
        setBtnCancelState("disabled");
        setBtnEditState("disabled");
        setBtnDelState("disabled");
      } else {
        toast.error("Greška u brisanju korisnika !", {
          theme: "colored",
        });
      }
    } catch (err) {
      toast.error(err, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="card col-md-9">
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>UNOS/EDIT KORISNIKA</h5>
        </div>

        <div className="col-md-4 float-end">
          <div className="input-group ">
            <span className="input-group-text">
              <i>
                <FaSearch />
              </i>
            </span>
            <select
              className="form-select col-md-4"
              value={selectedOption}
              onChange={changeSelect}
            >
              <option value="0">Izaberite korisnika...</option>
              {options.map((option) => (
                <option
                  value={option.id}
                  key={option.id}
                >{`${option.prezime} ${option.ime}`}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row g-3 d-flex justify-content-start">
          <div className="form-group col-md-2">
            <label htmlFor="name">Ime*</label>
            <span className="float-end text-small text-danger">{errName}</span>

            <input
              type="text"
              className="form-control fcontrol"
              name="name"
              value={name}
              disabled={disabled}
              onChange={(e) => setName(e.target.value)}
              ref={inputRef}
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="lastname">Prezime*</label>
            <span className="float-end text-small text-danger">
              {errLastName}
            </span>
            <input
              type="text"
              className="form-control fcontrol"
              name="lastname"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              disabled={disabled}
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="phone">Telefon</label>
            <input
              type="text"
              className="form-control fcontrol"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={disabled}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="tables">Tablice</label>
            <input
              type="text"
              className="form-control fcontrol"
              name="tables"
              value={tables}
              onChange={(e) => handleTables(e.target.value)}
              disabled={disabled}
            />
          </div>
        </div>

        <hr />
        <div className="row g-3">
          <div className="d-flex justify-content-center">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="rbRegime"
                value="1"
                id="rbRegimeSt"
                disabled={disabled}
                checked={selectMode === MODE_STANDARD}
                onChange={(e) => setSelectMode(e.target.value)}
                style={{ cursor: "pointer" }}
              />
              <label className="form-check-label" htmlFor="rbRegimeSt">
                STALNI REŽIM
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="rbRegime"
                value="2"
                id="rbRegimeTemp"
                disabled={disabled}
                checked={selectMode === MODE_TEMPORARY}
                onChange={(e) => setSelectMode(e.target.value)}
                style={{ cursor: "pointer" }}
              />
              <label className="form-check-label" htmlFor="rbRegimeTemp">
               PRIVREMEN REŽIM
              </label>
            </div>
          </div>
        </div>

        <div className="row g-3 mt-1 d-flex justify-content-center">
          <div className="form-group col-md-4">
            <label htmlFor="selectMode">
              Režim pristupa
            </label>
            <select
              className="col form-select"
              name="selectRegimAccess"
              disabled={disabled}
              value={selectRegime}
              onChange={e=>setSelectRegime(e.target.value)}
            >
              <option value="1">Pristup samo u režimu utakmice</option>
              <option value="2">Pristup samo u standardnom režimu</option>
            </select>
          </div>
        </div>

        <hr />
        {/* napomena */}
        <div className="row g-3 mt-1">
          <div className="form-group">
            <label htmlFor="idCUNote">Napomena</label>
            <textarea
              className="form-control"
              name="note"
              rows="3"
              value={note}
              style={{ resize: "none" }}
              onChange={(e) => setNote(e.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <div className="card-footer mt-4 bg-white">
        <button
          className="col-md-2 btn btn-outline-primary"
          disabled={btnNewState}
          onClick={toggleDisabled}
        >
          NOV UNOS
        </button>

        <button
          className="col-md-2 btn btn-outline-primary m-1"
          disabled={btnEditState}
          onClick={editHandle}
        >
          EDIT
        </button>

        <button
          className="col-md-2 btn btn-outline-primary m-1"
          disabled={disabled}
          onClick={saveCardUser}
        >
          SNIMI
        </button>

        <button
          className="col-md-2 btn btn-outline-primary m-1"
          disabled={disabled}
          onClick={cancelHandle}
        >
          ODLOŽI
        </button>
        <button
          className="col-md-2 btn btn-outline-danger btn-block float-end m-1"
          disabled={btnDelState}
          onClick={handleDelete}
        >
          OBRIŠI
        </button>
      </div>
      <ToastContainer />
    </div>
    // </div>
  );
}

export default CardUsers;
