/**
 * @author DAL (VS)
 * @version 1.0.4
 */

import { useState, useEffect } from "react";
import Sidebar from "./views/Sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Container from "./views/Container";

import Login from "./views/Login";
import Logout from "./views/Logout";
import CardUsers from "./views/CardUsers";
import ListUsers from "./views/ListUsers";
import Events from "./views/Events";
import Admin from "./views/Admin";
import Readers from "./views/Readers";
import ExportToWL from "./views/WhiteList/ExportWL.jsx";
import CheckWL from "./views/WhiteList/CheckWL.jsx";


import "./css/main.css";

function App() {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const ls = localStorage.getItem("login")
    setIsLogged(ls === 'true');
  }, []);

  const handleLogin = () => {
    setIsLogged(true);
    localStorage.setItem("login", "true");
  };

  const handleLogout = () => {
    setIsLogged(false);
    localStorage.setItem("login", "false");
  };

  return (
    //basename="/namenska-wl"
    <Router>
      {isLogged && <Sidebar />}
      <Container>
        <Routes>
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="logout" element={<Logout onLogout={handleLogout} />} />
          <Route path="cardusers" element={<CardUsers />} />
          <Route path="listusers" element={<ListUsers />} />
          <Route path="events" element={<Events />} />
          <Route path="export_wl" element={<ExportToWL />} />
          <Route path="check_wl" element={<CheckWL />} />
          <Route path="register" element={<Admin />} />
          <Route path="readers" element={<Readers />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
