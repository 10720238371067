/**
 * forma za registraciju korisnika
 * @author DAL (VS)
 * @version 1.0.2
 */

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { BASE_URL, BG_COLOR_TABLE_RED } from "./Global";

function Register() {
  const stylesTableReg = {
    headCells: {
      style: {
        backgroundColor: BG_COLOR_TABLE_RED,
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  };

  const columnsTableReg = [
    {
      name: "KORISNIČKO IME",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "IME I PREZIME",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "ROLE",
      selector: (row) => row.role,
      width: "100px",
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
    },
    {
      name: "KREIRAN",
      selector: (row) => row.created_at,
    },
    {
      name: "UPDATE",
      selector: (row) => row.updated_at,
    },
    {
      name: "EDIT",
      button: true,
      cell: (row) => (
        <div>
          <button
            className="btn btn-outline-danger m-1"
            title="Brisanje"
            onClick={(e) => handleDelReg(row.username)}
          >
            <FaTrash />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  useEffect(() => {
    fetchAllRegister();
    clearErrors();
    clearItems();
  }, []);

  const [regData, setRegData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");

  //edit forma
  const [isEditVisible, setEditVisible] = useState(false);

  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [role, setRole] = useState("1");
  
  //error edit forme
  const [errUserName, setErrUserName] = useState("");
  const [errName, setErrName] = useState("");
  const [errLName, setErrLName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPass, setErrPass] = useState("");
  const [errRole, setErrRole] = useState("");

  const NoDataCards = () => <div>Ne postoje podaci !</div>;

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = regData.filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" && field.toLowerCase().includes(value)
      )
    );
    setFilterText(value);
    setFilteredData(filtered);
  };

  const handleRoleSelect = (e) => {
    setRole(e.target.value);
  };

  

  //taster brisanje
  const handleDelReg = (username) => {
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Obrisani podatak je trajno obrisan !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRegUser(username);
      }
    });
  };

  const visibleNewUserForm = () => {
    clearItems();
    setEditVisible(true);
  };

  const hideNewUserForm = () => {
    setEditVisible(false);
  };

  const handleSaveUser = (e) => {
    e.preventDefault();
    saveNewRegUser();
  };

  //brisanje inputa gresaka
  const clearErrors = () => {
    setErrUserName("");
    setErrName("");
    setErrLName("");
    setErrEmail("");
    setErrPass("");
  };

  //brisanje unetih vrednosti
  const clearItems = () => {
    setUserName("");
    setName("");
    setLastName("");
    setEmail("");
    setPass("");
    setRole("1");
  };

  //preuzmi sve korisnike
  const fetchAllRegister = async () => {
    const url = `${BASE_URL}/fetchAllRegister`;
    try {
      const response = await axios.get(url);

      if (response.data.status === 200) {
        setFilteredData(response.data.result);
        setRegData(response.data.result);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //snimi korisnika
  const saveNewRegUser = async () => {
    clearErrors();

    const url = `${BASE_URL}/saveNewRegUser`;
   

    const data = {
      userName,
      name,
      lastName,
      email,
      pass,
      role,
     
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno unet podatak !",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchAllRegister();
      } else if (response.data.status === 500) {
        toast.error(response.data.result);
      } else {
        Object.entries(response.data).forEach(([key, val]) => {
          switch (key) {
            case "userName":
              setErrUserName(val);
              break;
            case "name":
              setErrName(val);
              break;
            case "lastName":
              setErrLName(val);
              break;
            case "email":
              setErrEmail(val);
              break;
            case "pass":
              setErrPass(val);
              break;
            case "role":
              setErrRole(val);
              break;
            default:
              break;
          }
        });
        toast.error("Greška u unosu podataka !");
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  //brisanje
  const deleteRegUser = async (username) => {
    const url = `${BASE_URL}/deleteRegUser/${username}`;
    try {
      const response = await axios.delete(url);
      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je obrisan korisnik !",
          showConfirmButton: false,
          timer: 2000,
        });
        fetchAllRegister();
      } else {
        toast.error(response.data.result);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

 

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>REGISTRACIJA KORISNIKA</h5>
        </div>
        <div className="col-md-4 float-end">
          <input
            type="text"
            className="form-control"
            placeholder="Filtriranje..."
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="card-body">
        <label htmlFor="idCUNote">Lista registrovanih korisnika</label>
        <DataTable
          columns={columnsTableReg}
          data={filteredData}
          fixedHeader
          pagination={true}
          striped={true}
          highlightOnHover={true}
          paginationPerPage={10}
          customStyles={stylesTableReg}
          fixedHeaderScrollHeight="400px"
          noDataComponent={<NoDataCards />}
        />
      </div>
      <div className="card-footer mt-4 bg-white">
        <button
          className="col-md-3 btn btn-outline-primary float-start"
          onClick={visibleNewUserForm}
        >
          UNOS NOVOG KORISNIKA
        </button>
      </div>

      {/* unos novog korisnika */}
      {isEditVisible && (
        <div className="my-modal-dialog-two">
          <div
            className="card my-modal-dialog"
            style={{ width: "40%", marginLeft: "10%", marginTop: "-10%" }}
          >
            <div className="my-card-header">
              <h5>UNOS NOVOG KORISNIKA</h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <div className="col form-group col-md-6">
                  <label htmlFor="username">Korisničko ime*</label>
                  <span className="float-end text-small text-danger">
                    {errUserName}
                  </span>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="col form-group col-md-6">
                  <label htmlFor="name">Ime*</label>
                  <span className="float-end text-small text-danger">
                    {errName}
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row g-3">
                <div className="col form-group col-md-6">
                  <label htmlFor="lastname">Prezime*</label>
                  <span className="float-end text-small text-danger">
                    {errLName}
                  </span>
                  <input
                    type="text"
                    name="lastname"
                    className="form-control"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="col form-group col-md-6">
                  <label htmlFor="email">Email*</label>
                  <span className="float-end text-small text-danger">
                    {errEmail}
                  </span>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="row g-3">
                <div className="col form-group col-md-6">
                  <label htmlFor="pass">Lozinka*</label>
                  <span className="float-end text-small text-danger">
                    {errPass}
                  </span>
                  <input
                    type="password"
                    name="pass"
                    className="form-control"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                  />
                </div>
                <div className="col form-group col-md-6">
                  <label htmlFor="rola">Rola</label>
                  <select
                    className="col form-select col-md-6"
                    name="rola"
                    value={role}
                    onChange={handleRoleSelect}
                  >
                    <option value="1">Korisnik</option>
                    <option value="2">Admin</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-outline-primary col-md-4 float-start "
                onClick={handleSaveUser}
              >
                SNIMI
              </button>
              <button
                className="btn btn-outline-success col-md-4 float-end"
                onClick={hideNewUserForm}
              >
                ZATVORI
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default Register;
