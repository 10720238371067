/**
 * forma za pregled korisnika kartica i unos/edit kartica
 * @author DAL (VS)
 * @version 1.0.4
 */

import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-data-table-component-extensions/dist/index.css";

import { FaPen, FaTrash } from "react-icons/fa";
import { format } from "date-fns";

import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BASE_URL, BG_COLOR_TABLE_RED, DATE_FORMAT,  objModes } from "./Global";

function ListUsers() {
  const stylesTableUsers = {
    headCells: {
      style: {
        backgroundColor: BG_COLOR_TABLE_RED,
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  };

  const stylesTableCards = {
    headCells: {
      style: {
        backgroundColor: BG_COLOR_TABLE_RED,
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
    tableWrapper: {
      style: {
        height: "100px",
        overflowY: "auto",
      },
    },
  };

  //glavna tabela
  const columnsTableUsers = [
    {
      name: "KORISNIK",
      selector: (row) => row.ime,
      sortable: true,
      width: "250px",
    },

    {
      name: "TELEFON",
      selector: (row) => row.telefon,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {row.telefon}
        </div>
      ),
    },
    {
      name: "TABLICA",
      selector: (row) => row.tablica.toUpperCase(),
      sortable: true,
      width: "150px",
    },
    {
      name: "TIP",
      selector: (row) => row.korisnik_mod_name,
      sortable: true,
      width: "130px",
    },
    {
      name: "REŽIM PRISTUPA",
      selector: (row) => row.korisnik_rezim_name,
      sortable: true,
      width: "160px",
    },
    {
      name: "QR DODELJEN",
      sortable: true,
      cell: (row) => row.qr_code,
    },
    {
      name: "UNOS",
      button: true,
      width: "200px",
      cell: (row) => (
        <button
          className="btn btn-outline-primary "
          title="Nov unos/editovanje kartica"
          disabled={!row.korisnik_mod || !row.korisnik_rezim}
          onClick={(e) => handleCards(e, row.id, row)}
        >
          <FaPen />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  //edit tabela
  const columnsTableCard = [
    {
      name: "ID",
      selector: (row) => row.id,
      omit: true,
    },
    {
      name: "AKTIVNA",
      selector: (row) => row.aktivan,
    },
    {
      name: "QR",
      selector: (row) => row.qr_code,
    },
    {
      name: "qr_copy",
      selector: (row) => row.qr_code,
      omit: true,
    },
    {
      name: "EDIT",
      button: true,
      cell: (row) => (
        <div className="">
          <button
            className="btn btn-outline-primary"
            onClick={(e) => handleEditCard(row)}
          >
            <FaPen />
          </button>

          <button
            className="btn btn-outline-danger"
            onClick={(e) => handleDelCards(row.id, row.qr_code)}
          >
            <FaTrash />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const paginationOptionsTableUsers = {
    rowsPerPageText: "",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
  };

  const paginationOptionsTableCards = {
    rowsPerPageText: "",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
  };

  const [data, setData] = useState([]);
  const [cardDatas, setCardDatas] = useState([]);
  const [getRow, setRow] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");

  //unos kartica
  const [isNewCardVisible, setIsNewCardVisible] = useState(false);
  const [isReadCardForm, setIsReadCardForm] = useState(false);
  const [activeCard, setActiveCard] = useState(false);
  const [idCardUser, setIdCardUser] = useState();
  const [nameUser, setNameUser] = useState("");

  //mode rezim
  const [modeUser, setModeUser] = useState("");
  const [regimUser, setRegimUser] = useState("");

  //qr kode
  const [getQrValue, setQrValue] = useState(null);
  const [getQrValueCopy, setQrValueCopy] = useState(null);

  const [isEditCardVisible, setIsEditCardVisible] = useState(false);
  const [editModes, setEditMode] = useState(false);

  const [getDateValid, setDateValid] = useState("");

  const NoDataCards = () => <div>Ne postoje podaci !</div>;

  const handleDateValid = (date) => {
    setDateValid(date);
  };

  const handleSaveCard = () => {
    setRegimUser(getRow.korisnik_rezim);
    setModeUser(getRow.korisnik_mod);
    setIsEditCardVisible(true);
    setEditMode(false);
    setActiveCard(false);
    setQrValue("");
    setQrValueCopy("");
  };

  const handleEditCard = (row) => {
    setActiveCard(!!row.aktivan);
    setQrValue(row.qr_code);
    setQrValueCopy(row.qr_code);
    setModeUser(row.korisnik_mod);
    setRegimUser(row.korisnik_rezim);
    setIsEditCardVisible(true);
    setEditMode(true);
    setDateValid(row.datum_vazenja);
  };

  const handleSaveCards = (e) => {
    e.preventDefault();
    if (getQrValue === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "QR broj je obavezan !!!",
      });
      return;
    }
    saveUserCard();
    setActiveCard(false);
    setQrValue("");
    setQrValueCopy("");
    setModeUser("");
    setRegimUser("");
    setDateValid("");
  };

  const handleDelCards = (id, qr_code) => {
    Swal.fire({
      title: "Da li ste sigurni ?",
      text: "Obrisani podatak će biti trajno obrisan !",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Odloži",
      confirmButtonText: "Da",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserCard(id, qr_code);
      }
    });
  };

  const readCardRef = useRef();

  const handleCards = (e, id, row) => {
    e.preventDefault();
    setRow(row);
    setCardDatas([]);
    setNameUser("");
    setActiveCard(false);
    setQrValue("");
    setQrValueCopy("");
    setIdCardUser(id);
    fetchCard(id);
    setIsNewCardVisible(!isNewCardVisible);
    setModeUser(row.korisnik_mod);
    setRegimUser(row.korisnik_rezim);
    setDateValid(row.datum_vazenja);
   
  };

  const hideListCardForm = () => {
    setIsNewCardVisible(false);
  };

  const hideReadCardForm = () => {
    setIsReadCardForm(false);
  };

  const handleReadCardValue = (e) => {
    setQrValue(e.target.value.toUpperCase());
  };

  const hideEditForm = () => {
    setEditMode(false);
    setIsEditCardVisible(false);
  };

  const handleClearCardValue = () => {
    setQrValue("");
    readCardRef.current.focus();
  };

  const handleActiveCard = (e) => {
    setActiveCard(e.target.checked);
  };

  useEffect(() => {
    getCardsUserList();
  }, [isNewCardVisible]);

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = data.filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" && field.toLowerCase().includes(value)
      )
    );
    setFilterText(value);
    setFilteredData(filtered);
  };

  /**
   * preuzima listu korisnika kartica
   * sa tasterom za unos kartica
   */
  const getCardsUserList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getCardsUserList`);
      if (response.data.status === 200) {
        setData(response.data.result);
        setFilteredData(response.data.result);
      } else {
        toast.error(response.data, {
          theme: "colored",
          hideProgressBar: true,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };

  /**
   * preuzmi sve kartice korisnika
   * @param {*} id id broj korisnika
   */
  const fetchCard = async (id) => {
    const url = `${BASE_URL}/fetchCards/${id}`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        setCardDatas(response.data.result);
        setNameUser(response.data.result[0].ime);
      } else if (response.data.status === 404) {
        // toast.error("Ne postoji QR kod za ovog korisnika", {
        //   theme: "colored",
        // });
        setCardDatas(response.data.result);
      } else if (response.data.status === 500) {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  /**
   * brisanje kartice korisnika
   * @param id id pozicija u tabeli
   * @param qr_code qr kod vrednost
   */
  const deleteUserCard = async (id, qr_code) => {
    const url = `${BASE_URL}/deleteUserCard/${id}/${qr_code}`;
    try {
      const response = await axios.delete(url);
      if (response.data.status === 200) {
        fetchCard(idCardUser);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je obrisana kartica !",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  /**
   * snimanje kartice korisnika
   */
  const saveUserCard = async () => {
    const url = editModes
      ? `${BASE_URL}/updateUserCard`
      : `${BASE_URL}/saveUserCard`;

    const date_valid = format(getDateValid, DATE_FORMAT);


    const data = {
      idCardUser,
      qr_code: getQrValue,
      qr_code_copy: getQrValueCopy,
      activeCard: Number(activeCard),
      date_valid,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Uspešno je unet podatak !",
          showConfirmButton: false,
          timer: 2000,
        });
        setIsEditCardVisible(false);
        fetchCard(idCardUser);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: "colored",
      });
    }
  };

  /**
   * kreira jedan qr kod
   */
  const getOneQrCode = async () => {
    const url = `${BASE_URL}/getOneQrCode`;
    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        setQrValue(response.data.result);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>LISTA KORISNIKA</h5>
        </div>
        <div className="col-md-4 float-end">
          <input
            type="text"
            className="form-control"
            placeholder="Filtriranje..."
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="card-body">
        <div className="row g-3 d-flex justify-content-start">
          <DataTable
            columns={columnsTableUsers}
            data={filteredData}
            fixedHeader
            pagination={true}
            striped={true}
            highlightOnHover={true}
            paginationPerPage={10}
            paginationComponentOptions={paginationOptionsTableUsers}
            customStyles={stylesTableUsers}
          />
        </div>
      </div>

      {/* modal window */}
      {isNewCardVisible && (
        <div className="modal-overlay">
          <div className="card my-modal-dialog" style={{ width: "55%" }}>
            <div className="my-card-header d-flex justify-content-between">
              <h6>LISTA KARTICA </h6>
              <div>
                <h6 className="text-primary">{objModes.arrMode[modeUser]}</h6>
                <h6 className="text-primary">{objModes.arrRegime[regimUser]}</h6>
              </div>

              <p className="fs-6 fw-medium text-center">
                {nameUser.toUpperCase()}
              </p>
            </div>
            <div className="card-body">
              {/* aktivna kartica */}
              {isEditCardVisible && (
                <div className="modal-overlay">
                  <div
                    className="card my-modal-dialog"
                    style={{ width: "40%" }}
                  >
                    <div className="my-card-header d-flex justify-content-between">
                      <h6>
                        {editModes
                          ? "EDITOVANJE QR KODA"
                          : "UNOS NOVOG QR KODA"}{" "}
                      </h6>
                      <div>
                        <h6 className="text-primary">{objModes.arrMode[modeUser]}</h6>
                        <h6 className="text-primary">{objModes.arrRegime[regimUser]}</h6>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="m-1 form-group form-switch col-md-5 d-flex">
                          <input
                            className="form-check-input form-control"
                            type="checkbox"
                            role="switch"
                            name="activeCard"
                            checked={activeCard}
                            onChange={handleActiveCard}
                            aria-labelledby="switch-label"
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            htmlFor="activeCard"
                            className="form-check-label"
                          >
                            Aktivna kartica
                          </label>
                        </div>
                      </div>

                      <div className="row g-3 d-flex justify-content-around mt-3">
                        <div className="col form-group col-md-3">
                          <label htmlFor="qrcode">QR kod</label>
                          <input
                            type="text"
                            className="form-control text-center"
                            name="qrcode"
                            value={getQrValue}
                            disabled
                          />
                        </div>

                        {modeUser === 1 && regimUser === 2 && (
                          <div className="col form-group col-md-3">
                            <label htmlFor="datevalid">Datum važenja</label>
                            <DatePicker
                              selected={getDateValid}
                              onChange={handleDateValid}
                              value={getDateValid}
                              className="form-control"
                              name="datevalid"
                              style={{ width: "700px" }}
                              dateFormat={"YYYY-MM-dd"}
                            />
                          </div>
                        )}

                        <div className="col col-md-5">
                          <button
                            className="btn btn-primary mt-4"
                            onClick={getOneQrCode}
                          >
                            GENERIŠI QR KOD
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer mt-4 bg-white">
                      <button
                        className="btn btn-outline-primary float-start"
                        onClick={handleSaveCards}
                      >
                        SNIMI UNOS
                      </button>

                      <button
                        className="btn btn-outline-success float-end"
                        onClick={hideEditForm}
                      >
                        ZATVORI
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="row g-3">
                <div className="col">
                  <label htmlFor="table" className="form-label">
                    Tabela unetih kartica
                  </label>
                  <DataTable
                    columns={columnsTableCard}
                    data={cardDatas}
                    name="table"
                    fixedHeader
                    striped={true}
                    highlightOnHover={true}
                    pagination={true}
                    paginationPerPage={5}
                    paginationComponentOptions={paginationOptionsTableCards}
                    customStyles={stylesTableCards}
                    noDataComponent={<NoDataCards />}
                  />
                </div>
              </div>
            </div>

            {/* footer */}
            <div className="card-footer mt-4 bg-white">
              <button
                className="btn btn-outline-primary float-start"
                onClick={handleSaveCard}
              >
                UNOS NOVOG QR KODA
              </button>
              <button
                className="btn btn-outline-success float-end"
                onClick={hideListCardForm}
              >
                ZATVORI
              </button>
            </div>
          </div>
        </div>
      )}

      {isReadCardForm && (
        <div className="my-modal-dialog-two">
          <div
            className="card my-modal-dialog"
            style={{ width: "30%", marginLeft: "10%", marginTop: "-10%" }}
          >
            <div
              className="my-card-header text-center"
              style={{ backgroundColor: "#be2625", color: "white" }}
            >
              <h5>OČITAVANJE KARTICE</h5>
            </div>
            <div className="card-body">
              <div className="row g-3">
                <input
                  type="text"
                  style={{ fontSize: 40 }}
                  value={getQrValue}
                  onChange={handleReadCardValue}
                  ref={readCardRef}
                  placeholder="Prislonite karticu na čitač..."
                />
                <p className="small text-end">Moguć je i ručni unos</p>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-outline-success float-start"
                onClick={hideReadCardForm}
              >
                UNESI
              </button>

              <button
                className="btn btn-outline-danger float-end"
                onClick={handleClearCardValue}
              >
                OBRIŠI UNOS
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}
export default ListUsers;
