/**
 * forma za pregled eventa korisnika
 * @author DAL (VS)
 * @version 1.0.2
 */

import React, { useState} from "react";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import { format } from "date-fns";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-datepicker/dist/react-datepicker.css";

import { BASE_URL, DATE_FORMAT } from "./Global";

function Events() {
  const stylesTableUsers = {
    headCells: {
      style: {
        backgroundColor: "#2596be",
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  };

  const columnsTableEvents = [
    {
      name: "SN",
      selector: (row) => row.sn_card,
      sortable: true,
    },
    {
      name: "UID",
      selector: (row) => row.uid_card,
      sortable: true,
    },
    {
      name: "IME I PREZIME",
      selector: (row) => row.ime,
      sortable: true,
    },
    {
      name: "DATUM",
      selector: (row) => row.datum,
      sortable: true,
    },
    {
      name: "VREME",
      selector: (row) => row.vreme,
      sortable: true,
    },
  ];

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [eventData, setEventData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");

  const NoDataCards = () => <div>Ne postoje podaci !</div>;

  const handleDateFrom = (date) => {
    setDateFrom(format(date, DATE_FORMAT));
  };
  const handleDateTo = (date) => {
    setDateTo(format(date, DATE_FORMAT));
  };

  const handleFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = eventData.filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" && field.toLowerCase().includes(value)
      )
    );

    setFilterText(value);
    setFilteredData(filtered);

  };

  const handleCheckEvent = () => {
    if (dateFrom === null || dateTo === null) return;

    const ds = format(dateFrom, DATE_FORMAT);
    const du = format(dateTo, DATE_FORMAT);

    if (ds > du) {
      toast.error("Greška u unosu datuma !", {
        theme: "colored",
        hideProgressBar: true,
      });
      return;
    }

    getEvents();
  };

  /**
   * prolazi korisnika
   */
  const getEvents = async () => {
    const url = `${BASE_URL}/events`;

    const data = {
      dateFrom,
      dateTo,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === 200) {
        setEventData(response.data.result);
        setFilteredData(response.data.result);
      } else {
        toast.error(response.data.result, {
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      });
    }
  };

  return (
    <div className="card col-md-9">
      <div className="my-card-header">
        <div className="col-md-5 float-start ">
          <h5>EVIDENCIJA PROLAZA</h5>
        </div>
        <div className="col-md-4 float-end">
          <input
            type="text"
            className="form-control"
            placeholder="Filtriranje..."
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="card-body">
        <div className="row g-3 d-flex justify-content-around">
          <div className="form-group col-md-3">
            <label htmlFor="activesince">Datum Od*</label>

            <DatePicker
              selected={dateFrom}
              onChange={handleDateFrom}
              value={dateFrom}
              className="form-control"
              style={{ width: "700px" }}
              dateFormat={DATE_FORMAT}
              autoComplete="off"
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="activeuntil">Datum Do*</label>
            <DatePicker
              selected={dateTo}
              onChange={handleDateTo}
              value={dateTo}
              className="form-control"
              name="activeuntil"
              dateFormat={DATE_FORMAT}
              autoComplete="off"
            />
          </div>

          <button
            className="col col-md-4 btn btn-primary"
            onClick={handleCheckEvent}
          >
            ODREDI
          </button>

          <hr/>

          <div className="row g-3 d-flex justify-content-start">
            <DataTable
              columns={columnsTableEvents}
              data={filteredData}
              fixedHeader
              pagination={true}
              striped={true}
              highlightOnHover={true}
              paginationPerPage={10}
              customStyles={stylesTableUsers}
              fixedHeaderScrollHeight="400px"
              noDataComponent={<NoDataCards />}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Events;
