/**
 * @version 1.0.3
 */

import React, { useState } from "react";
import styled from "styled-components";
import { SidebarMenu } from "./SidebarMenu";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import CZImage  from "../assets/cz.png";
import DALImage from "../assets/dal.jpg";
import { VERSION, ROLA, USER } from "./Global.jsx";
import { BASE_URL, BG_COLOR_RED } from "./Global";

const Nav = styled.div`
  background:#be254a;
  border-bottom:1px solid white;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 250px;
`;

const SidebarNav = styled.nav`
  background: #be254a;
  border-right:1px white solid;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarNavDiv = styled.div`
  min-width: 235px;
  padding: 20px;
  text-align: center;
  color: white;
`;

const Sidebar = () => {
  const HELP_FILE_NAME = "Upustvo_za_white_listu.pdf";
  const STORAGE_FILES_PATH = "storage/Files/";
  const [sidebar, setSidebar] = useState(true);
  

  const handleHelpFile = () =>{
    window.open(
      `${BASE_URL}/${STORAGE_FILES_PATH}${HELP_FILE_NAME}`,
      "_blank"
    )
  }


  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav sidebar={sidebar}>
        <header className="col-md-11">
          <img
            style={{
              width: 110,
              height: 60,
              paddingLeft: 10,
              borderRadius: 10,
            }}
            src={DALImage}
            alt="ne postoji"
          />
          <div className="float-end">
            <button className="btn"
              onClick={handleHelpFile}
              style = {{
                fontSize:'15px',
                color:'white',
              }}
            >
             Upustvo programa
            </button>
            <p className="text-white fw-semibold text-center" 
                title="Verzija programa"
                style = {{ fontSize: '10px' }}
                >
              {VERSION}
            </p>
          </div>
        </header>
      </Nav>

      <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <SidebarNavDiv>
            <img
            style={{
              width: 150,
              height:150,
              paddingLeft: 10,
              
            }}
            src={CZImage}
            alt="ne postoji"
          />
           
            <hr />
          </SidebarNavDiv>
          {SidebarMenu.map((item, index) => {
            if (localStorage.getItem(ROLA) === USER && item.admin) {
              return null;
            }
            return <SubMenu item={item} key={index} />;
          })}
        </SidebarWrap>
      </SidebarNav>
    </IconContext.Provider>
  );
};

export default Sidebar;
