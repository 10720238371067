/**
 * sluzi za prikaz menija i podmenija 
 * @author DAL (VS)
 * @version 1.0.3
 */

import React from 'react';
import {
  FaUsers,
  FaList,
  FaUserTie,
  FaCogs,
  FaSignOutAlt,
  FaAngleDown,
  FaAngleUp,
  FaHireAHelper,
} from "react-icons/fa";

export const SidebarMenu = [
  {
    title: 'Korisnici',
    path: '#',
    icon: <FaUsers />,
    iconClosed: <FaAngleDown/>,
    iconOpened: <FaAngleUp/>,

    subNav: [
      {
        title: 'Unos/Edit',
        path: '/cardusers',
        icon: "",
      },
      {
        title: 'Lista korisnika',
        path: '/listusers',
        icon: ""
      },
      //  {
      //   title: 'Evidencija prolaza',
      //   path: '/events',
      //   icon: ""
      // },

    ]
  },
  {
    title: 'White lista',
    path: '#',
    icon: <FaList />,
    iconClosed: <FaAngleDown/>,
    iconOpened: <FaAngleUp/>,

    subNav: [
      {
        title: 'Eksport kartica',
        path: '/export_wl',
        icon: '',
        cName: 'sub-nav'
      },
      {
        title: 'Provera white liste',
        path: '/check_wl',
        icon: '',
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'Administracija',
    path: '#',
    admin: true,
    icon: <FaUserTie />,
    iconClosed: <FaAngleDown/>,
    iconOpened: <FaAngleUp/>,
    subNav: [
      {
        title: 'Registracija korisnika',
        path: '/register',
        icon: '',
        cName: 'sub-nav'
      },
    ]
  },
  {
    title: 'Podešavanje',
    path: '#',
    admin: true,
    icon: <FaCogs />,
    iconClosed: <FaAngleDown/>,
    iconOpened: <FaAngleUp/>,
    subNav: [
      {
        title: 'Čitača',
        path: '/readers',
        icon: '',
        cName: 'sub-nav'
      }
    ]
  },
  // {
  //   title: 'Pomoć',
  //   path: '#',
  //   icon: <FaHireAHelper />,
  //   iconClosed: <FaAngleDown/>,
  //   iconOpened: <FaAngleUp/>,
  //   subNav: [
  //     {
  //       title: 'Preuzmi upustvo',
  //       path: '/displayHelp',
  //       icon: '',
  //       cName: 'sub-nav'
  //     }
  //   ]
  // },
  {
    title: 'Logout',
    path: '/logout',
    icon:<FaSignOutAlt />,
    subNav: [
      {
        title: '',
        path: '#',
        icon: '',
        cName: 'sub-nav'
      }
    ]
  },

];